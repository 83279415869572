@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Malayalam:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: "typicons";
  src: url("fonts/typicons.eot") format("embedded-opentype"),
    url("fonts/typicons.woff2") format("woff2"),
    url("fonts/typicons.woff") format("woff"),
    url("fonts/typicons.ttf") format("truetype"),
    url("fonts/typicons.svg") format("svg");
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Jost", sans-serif;
}

:root {
  --card: 0 0% 100%;
  --darkcard: 240deg 42.86% 9.61%;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0px 1px 2px 0px rgba(15, 22, 36, 0.06),
    0px 1px 3px 0px rgba(15, 22, 36, 0.1) !important;
  --card-foreground: 222.2 84% 4.9%;
  --text-color: #ffffff;
}

:root-dark {
  --text-color: #ffffff;
}

.app .rdw-editor-toolbar {
  position: -webkit-sticky;
  position: sticky;
  top: 58px;
  background-color: #0e0e23;
  z-index: 999;
}

.app .rdw-editor-main {
  border: 0.5px solid #4b4b4b8c;
  border-radius: 8px;
  min-height: 50vh;
  padding-left: 12px;
}

.app .rdw-editor-main a {
  color: #000 !important;
}

.app .rdw-dropdownoption-active {
  background: #f5f5f5;
}

.app .rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.app .rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.app .rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.app .rdw-dropdown-selectedtext {
  color: #000 !important;
}

.content {
  /* max-height: 100vh; */
  overflow: auto;
}

.dashboard {
  height: auto;
}

.gap-2 {
  gap: 1.5rem;
}

.app {
  display: flex;
  position: relative;
  justify-content: center;
  min-width: 100vw;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ps-sidebar-container::-webkit-scrollbar {
  display: none;
}

.ps-sidebar-container::-moz-scrollbar {
  display: none;
}

.scrollhost {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  /*just hides the scrollbar for firefox */
}

.ps-sidebar-root {
  border: none !important;
}

@media screen and (max-width: 500px) {
  .TopPageBar {
    display: none !important;
  }
}

::-webkit-calendar-picker-indicator {
  color: rgba(217, 255, 0, 0);
  opacity: 1;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 0.5rem;
  filter: sepia(100%);
}

::-webkit-calendar-picker-indicator:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
}

.dayBTn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5a5a5a;
  font-size: 14px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  gap: 1rem;
  margin: 0 !important;
}

.dayBTn:hover {
  background-color: #4cceab77;
}

.dayBTn.active {
  background-color: #4cceac;
  color: #ffffff;
  border-color: #4cceac;
}

.dayQty .qty {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dayQty .qty button {
  color: #0e0e23 !important;
}

.dayQty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
}

/* togg */
/* Genel stil */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
}

/* Giriş stil */
.toggle-switch .toggle-input {
  display: none;
}

/* Anahtarın stilinin etrafındaki etiketin stil */
.toggle-switch .toggle-label-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 24px;
  background-color: #dfdfdf;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Anahtarın yuvarlak kısmının stil */
.toggle-switch .toggle-label-2::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

/* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
.toggle-switch .toggle-input:checked + .toggle-label-2 {
  background-color: #4caf50;
}

.toggle-switch .toggle-input:checked + .toggle-label-2::before {
  transform: translateX(16px);
}

/* Light tema */
.toggle-switch.light .toggle-label-2 {
  background-color: #bebebe;
}

.toggle-switch.light .toggle-input:checked + .toggle-label-2 {
  background-color: #9b9b9b;
}

.toggle-switch.light .toggle-input:checked + .toggle-label-2::before {
  transform: translateX(6px);
}

/* Dark tema */
.toggle-switch.dark .toggle-label-2 {
  background-color: #4b4b4b;
}

.toggle-switch.dark .toggle-input:checked + .toggle-label-2 {
  background-color: #717171;
}

.toggle-switch.dark .toggle-input:checked + .toggle-label-2::before {
  transform: translateX(16px);
}

/* .sideBar .ps-sidebar-container {
  background-color: #0e0e23;
} */

.menu-nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 0 0 15px;
  justify-content: center;
  margin: 0 auto;
}

/* div:nth-child(1n+1) nav ul div:nth-child(1n+1){
  padding-left: 15px;
}  */

.ps-menu-button {
  position: relative;
  z-index: 1;
  /* text-align: center; */
  border-radius: 30px 0 0 30px;
  margin: 5px auto 0 0;
  border: 0 solid transparent;
  border-right: 0;
  width: inherit;
  height: auto !important;
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 4px !important;
  /* font-weight: 400;
  font-size: 14px; */
  color: hsla(0, 0%, 100%, 0.4);
}

.ps-menu-button.ps-active {
  padding-left: 5px !important;
}

.ps-menu-button.ps-active .ps-menu-icon {
  font-size: 18px;
  margin-right: 10px;
  margin-left: 1px;
  /* width: 36px;
  height: 36px; */
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  background: #00a233;
}

.ps-menu-button .ps-menu-label h5 {
  font-size: 14px;
  font-weight: 600;
}

.ps-menu-button.ps-active:hover {
  background-color: #eef1f9 !important;
}

.without .ps-menu-button.ps-active:hover {
  background-color: #2c3240 !important;
}

.ps-menu-button:hover .ps-menu-label h5 {
  margin-left: 5px;
  transition: margin-left 0.45s ease;
}

.ps-menu-label {
  text-align: left;
  display: flex;
  align-items: center;
}

.ps-menu-icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 0px;
}

.ps-menu-icon svg {
  width: 0.8em;
  height: 0.8em;
}

.ps-menu-button.ps-active::before,
.ps-menu-button.ps-active::after {
  border-right: 21px solid #0e0e23;
}

.ps-menu-button.ps-active::before {
  content: "";
  position: absolute;
  top: -30px;
  z-index: 9;
  right: 0;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0 0 48px 0;
}

.ps-menu-button.ps-active::after {
  content: "";
  position: absolute;
  top: 38px;
  z-index: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0 48px 0 0;
}

.ps-menu-button.ps-active .shape1 {
  height: 65px;
}

.ps-menu-button.ps-active .shape1 {
  position: absolute;
  top: -30px;
  width: 20px;
  height: 60px;
  right: 0;
}

.ps-menu-button.ps-active .shape2 {
  position: absolute;
  top: 27px;
  width: 20px;
  height: 30px;
  right: 0;
}

/* .ps-menu-button.ps-active .shape1,
.ps-menu-button.ps-active .shape2 {
  background: #ffffff;
} */

.popupMulti-popper .MuiAutocomplete-popper .MuiPaper-elevation ul {
  text-align: left;
}

.ps-collapsed nav .ps-menu-icon {
  margin: 0;
}

.ps-sidebar-container nav .menuOutlineBar {
  /* padding-left: 0.9rem; */
  border-bottom: 0.5px solid #fcfcfc;
}

ul div .mainReportLink:first-child {
  margin-top: 15px;
}

/* .MuiDataGrid-columnHeaders,
.MuiDataGrid-footerContainer {
  background-color: #0e0e23 !important;
} */

.header {
  /* background-color: #ffffffe6 !important; */
  border-bottom-width: 1px !important;
  border-color: #e2e8f0;
  border-bottom: 0 solid #e5e7eb;
  position: sticky;
  top: 0;
  z-index: 999;
}

.MuiAvatar-circular {
  width: 25px !important;
  height: 25px !important;
}

.MuiAvatar-circular img {
  width: auto;
}

.proSidebar {
  width: 235px;
  min-width: 235px;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.items-center {
  align-items: center !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex {
  display: flex !important;
}

.justify-between {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.bg-success {
  background-color: #2ecc71 !important;
}

.bg-info {
  background-color: #23b7e5 !important;
}

.bg-warning {
  background-color: #f39c12 !important;
}

.bg-danger {
  background-color: #e74c3c !important;
}

.color-white {
  color: #fff !important;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.no-margin {
  margin: 0 !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 16px;
}

.list-group .list-group-item {
  border-color: transparent;
  background-color: transparent;
  padding: 10px 0px;
}

.group-off .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0px 0px 0px;
}

.group-off .list-group .list-group-item:last-child {
  border-color: transparent;
  background-color: transparent;
  padding: 10px 0px 0px;
}

.widget-stat-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  line-height: 125px;
  text-align: center;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Badge */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-danger {
  color: #fff;
  background-color: #e74c3c;
}

.badge-info,
.label-info {
  background-color: #23b7e5;
}

.float-right {
  float: right !important;
}

.badge,
.label {
  padding: 3px 6px;
  line-height: inherit;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.badge-circle,
.label-circle {
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  padding: 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.bg-card {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--card) / var(--tw-bg-opacity)) !important;
}

.bg-darkcard {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--darkcard) / var(--tw-bg-opacity)) !important;
}

.shadow-none,
.shadow-sm {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.text-card-foreground {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--card-foreground) / var(--tw-text-opacity)) !important;
}

.p-4 {
  padding: 1rem !important;
}

.mb1 {
  margin-bottom: 0.5rem !important;
}

@media (min-width: 1280px) {
  .xl\:p-2 {
    padding: 1rem !important;
  }
}

@media (min-width: 1280px) {
  .xl\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}

.title-menu {
  margin-bottom: calc(1.5rem * calc(1 - 0)) !important;
}

.arrowIconSubMenu::before {
  content: "";
  font-family: typicons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  top: 8px;
  font-size: 12px;
  opacity: 0.4;
  /* margin-right: 22px; */
  /* color: hsla(0, 0%, 100%, .8); */
}

.height-calc {
  height: calc(100vh - 100px) !important;
}

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-menuIconButton,
.MuiIconButton-sizeSmall {
  color: #fcfcfc !important;
}

.MuiPopover-root .MuiPopover-paper.MuiMenu-paper {
  max-height: calc(100% - 410px) !important;
}

.qtyLabel input {
  padding: 5px 5px 5px;
  text-align: center;
  font-size: 14px;
}

/* .qtyLabel fieldset {
  border: none;
} */

.chipLabel {
  width: 259px;
}

.imagesUpdateStock {
  font-size: 22px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2e7d32;
  background: #2e7d323b;
  border-radius: 5px;
}

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.policyHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px 260px;
  background: white;
  box-shadow: rgba(40, 44, 63, 0.15) 0px 15px 40px -20px;
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
}
.policyHeader img {
  width: 50px;
}
.policyHeader h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.4px;
  color: rgba(2, 6, 12, 0.75);
}

.privacyPolicy {
  padding: 40px 260px 25px;
  margin-top: 80px;
  width: 100%;
  height: auto;
}

.privacyPolicy .prose a {
  color: #2563eb;
  text-decoration: underline;
  font-weight: 600;
}

.privacyPolicy .prose h3,
.privacyPolicy .prose h1,
.privacyPolicy .prose h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.privacyPolicy .prose ul {
  padding-left: 2rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .policyHeader {
    padding: 20px;
  }
  .privacyPolicy {
    padding: 40px 20px 25px !important;
    text-align: justify;
  }
  .privacyPolicy .prose h3,
  .privacyPolicy .prose h1,
  .privacyPolicy .prose h2 {
    text-align: left;
  }

  .privacyPolicy .prose h2 {
    font-size: 1.4rem;
  }
}
